import React from 'react';
import GlobalStyle from './globalStyles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';

//Pages
import Home from './pages/Home';
import HomeCN from './pages/HomeCN';
import UserAgreement from './pages/UserAgreementPage';
import UserAgreementCN from './pages/UserAgreementCNPage';
import PrivacyPolicy from './pages/PrivacyPolicyPage';
import PrivacyPolicyCN from './pages/PrivacyPolicyCNPage';
import SignUp from './pages/SignupPage';
import Download from './pages/DownloadPage';
import NotFound from './pages/NotFoundPage';
import Footer from './components/Footer/Footer';

function App() {
	return (
		<Router>
			<GlobalStyle />
			{/* <Navbar /> */}
			<Switch>
				<Route path="/" exact component={Home} />
				<Route path="/en" exact component={Home} />
				<Route path="/en/privacy-policy.html" exact component={PrivacyPolicy} />
				<Route path="/en/user-agreement.html" exact component={UserAgreement} />
				<Route path="/cn" exact component={HomeCN} />
				<Route path="/privacy-policy.html" exact component={PrivacyPolicyCN} />
				<Route path="/user-agreement.html" exact component={UserAgreementCN} />
				<Route path="/signup" exact component={SignUp} />
				<Route path="/download" exact component={Download} />
				<Route path="*" component={NotFound} />
			</Switch>
			<Footer />
		</Router>
	);
}

export default App;
