import React , { useState }from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, MainHeading } from '../../globalStyles';
import { HeroVideo, HeroSection, HeroText, ButtonWrapper, HeroImage, HeroButton } from './HeroStyles';

const Hero = () => {
	return (
		<HeroSection>
			{/* <HeroVideo src="./assets/ucl.mp4" autoPlay muted loop/> */}
			<HeroImage src="./assets/background.png"/>
			<Container>
				<MainHeading>Xscore.ai - AI Assistant</MainHeading>
				<HeroText>
					Sports app for enthusiasts, tailoring key info to your needs
				</HeroText>
				<ButtonWrapper>
					<a href="https://play.google.com/store/apps/details?id=com.kincho.sportreview&hl=gsw&gl=US&referrer=utm_source%3Dgoogle%26utm_medium%3Dorganic%26utm_term%3Dplaystore+sportchitz">
						<Button>Download Now</Button>
					</a>
					{/* <HeroButton>Find More</HeroButton> */}
				</ButtonWrapper>
			</Container>
		</HeroSection>
	);
};

export default Hero;
