import React from 'react';
import {
	FooterLinkItems,
	FooterLinkTitle,
	FooterLink,
	FooterLogo,
	SocialIcon,
	FooterRights,
	FooterSocialIcon,
	FooterWrapper,
	FooterAddress,
	FooterColumn,
	FooterGrid,
} from './FooterStyles';
import { footerData, footerSocialData } from '../../data/FooterData';
import { Row, FooterSection } from '../../globalStyles';

function Footer() {
	return (
		<FooterSection padding="4rem 0 2rem 0">
			<FooterWrapper>
				<FooterGrid justify="space-between">
					<FooterColumn id="footerLogo">
						<FooterLogo to="/">
							<SocialIcon src="./assets/logo.png" />
							Xscore.ai
						</FooterLogo>
						{/* <FooterAddress>
						中国（云南）自由贸易试验区昆明片区官渡区螺蛳湾国际商贸城三期市场 33 区 1121- 20936 号
						</FooterAddress> */}

						{/* <Row align="center" margin="auto  0 0 0" gap="1rem">
							{footerSocialData.map((social, index) => (
								<FooterSocialIcon
									key={index}
									href="/"
									target="_blank"
									aria-label={social.name}
								>
									{social.icon}
								</FooterSocialIcon>
							))}
						</Row> */}
					</FooterColumn>
					{/* {footerData.map((footerItem, index) => (
						<FooterLinkItems key={index}>
							<FooterLinkTitle>{footerItem.title}</FooterLinkTitle>
							{footerItem.links.map((link, linkIndex) => (
								<FooterLink key={linkIndex} to="/">
									{link}
								</FooterLink>
							))}
						</FooterLinkItems>
					))} */}
				</FooterGrid>
				<FooterRights>Xscore.ai © 2023</FooterRights>
			</FooterWrapper>
		</FooterSection>
	);
}

export default Footer;
