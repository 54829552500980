export const data = [
	{
		to: '/en/privacy-policy.html',
		text: 'Privacy Policy',
	},
	{
		to: '/en/user-agreement.html',
		text: 'User Agreement',
	},
	{
		to: '/cn',
		text: '中文 >',
	},
];
export const dataCN = [
	{
		to: '/privacy-policy.html',
		text: '隐私政策',
	},
	{
		to: '/user-agreement.html',
		text: '用户协议',
	},
	{
		to: '/',
		text: 'English >',
	},
];
