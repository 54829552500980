import React from 'react';
import UserAgreementCN from '../components/User-agreement/UserAgreementCN';
import NavbarCN from '../components/Navbar/NavbarCN';
function UserAgreementCNPage() {
	return (
		<>
			<NavbarCN />
			<UserAgreementCN />
		</>
	);
}

export default UserAgreementCNPage;
