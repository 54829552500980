import React from 'react';
import PrivacyPolicyCN from '../components/Privacy-policy/PrivacyPolicyCN';
import NavbarCN from '../components/Navbar/NavbarCN';
function PrivacyPolicyCNPage() {
	return (
		<>
			<NavbarCN />
			<PrivacyPolicyCN />
		</>
	);
}

export default PrivacyPolicyCNPage;
