import React, { useState } from 'react';
import {
	FormColumn,
	FormWrapper,
	FormInput,
	FormSection,
	FormRow,
	FormLabel,
	FormInputRow,
	FormMessage,
	FormButton,
	FormTitle,
} from './FormStyles';
import { Container } from '../../globalStyles';
import validateForm from './validateForm';

const Form = () => {
	const [name, setName] = useState('');
	// const [email, setEmail] = useState('');
	const [appId, setAppId] = useState('');
	const [reason, setReason] = useState('');
	// const [password, setPassword] = useState('');
	// const [confirmPass, setConfirmPass] = useState('');
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setSuccess(null);
		//validate form
		const resultError = validateForm({ name, appId, reason });

		if (resultError !== null) {
			setError(resultError);
			return;
		}

		// call api 
		try {
			let res = await fetch("https://reqres.in/api/users", {
				method: "POST",
				body: JSON.stringify({
					name: name,
					appId: appId,
					reason: reason,
				}),
			});
			let resJson = await res.json();
			console.log(resJson);
			if(res.status === 200){
			setSuccess('Application was submitted!');
			}else{
			setError("Some error occurred");
			}
		} catch (error) {
			setError(error);
		}

		setName('');
		setAppId('');
		setReason('');
		setError(null);
		setSuccess('Application was submitted!');
	};

	const messageVariants = {
		hidden: { y: 30, opacity: 0 },
		animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
	};

	const formData = [
		{ label: 'Name', value: name, onChange: (e) => setName(e.target.value), type: 'text' },
		{ label: 'App Id', value: appId , onChange: (e) => setAppId(e.target.value), type: 'text' },
		{ label: 'Reason', value: reason, onChange: (e) => setReason(e.target.value), type: 'textarea' },
		// {
		// 	label: 'Password',
		// 	value: password,
		// 	onChange: (e) => setPassword(e.target.value),
		// 	type: 'password',
		// },
		// {
		// 	label: 'Confirm Password',
		// 	value: confirmPass,
		// 	onChange: (e) => setConfirmPass(e.target.value),
		// 	type: 'password',
		// },
	];
	return (
		<FormSection>
			<Container>
				<FormRow>
					<FormColumn small>
						<FormTitle>Deletion Request </FormTitle>
						<FormWrapper onSubmit={handleSubmit}>
							{formData.map((el, index) => (
								<FormInputRow key={index}>
									<FormLabel>{el.label}</FormLabel>
									<FormInput
										type={el.type}
										placeholder={`Enter your ${el.label.toLocaleLowerCase()}`}
										value={el.value}
										onChange={el.onChange}
									/>
								</FormInputRow>
							))}

							<FormButton type="submit">Request for Delete</FormButton>
						</FormWrapper>
						{error && (
							<FormMessage
								variants={messageVariants}
								initial="hidden"
								animate="animate"
								error
							>
								{error}
							</FormMessage>
						)}
						{success && (
							<FormMessage
								variants={messageVariants}
								initial="hidden"
								animate="animate"
							>
								{success}
							</FormMessage>
						)}
					</FormColumn>
				</FormRow>
			</Container>
		</FormSection>
	);
};

export default Form;
