import React , { useState }from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, MainHeading } from '../../globalStyles';
import { HeroVideo, HeroSection, HeroText, ButtonWrapper, HeroImage, HeroButton } from './HeroStyles';

const HeroCN = () => {
	return (
		<HeroSection>
			{/* <HeroVideo src="./assets/ucl.mp4" autoPlay muted loop/> */}
			<HeroImage src="./assets/background.png"/>
			<Container>
				<MainHeading>Xscore.ai - 人工智能助手</MainHeading>
				<HeroText>
				一款卓越的智能体育应用程序，致力于轻松提供对你最重要的赛事信息。无论你是一个热衷的体育爱好者还是一位专注的赛事迷，都是量身定制，以满足你的一切需求
				</HeroText>
				<ButtonWrapper>
                    <a href="https://play.google.com/store/apps/details?id=com.kincho.sportreview&hl=gsw&gl=US&referrer=utm_source%3Dgoogle%26utm_medium%3Dorganic%26utm_term%3Dplaystore+sportchitz">
						<Button>下载</Button>
					</a>
					{/* <HeroButton>Find More</HeroButton> */}
				</ButtonWrapper>
			</Container>
		</HeroSection>
	);
};

export default HeroCN;
