import React from 'react';
import PrivacyPolicy from '../components/Privacy-policy/PrivacyPolicy';
import Navbar from '../components/Navbar/Navbar';
function PrivacyPolicyPage() {
	return (
		<>
			<Navbar />
			<PrivacyPolicy />
		</>
	);
}

export default PrivacyPolicyPage;
