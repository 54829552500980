import React from 'react';
import Carousel from '../components/Carousel/Carousel';
import { Content } from '../components/Content/Content';
import Features from '../components/Features/Features';
import Hero from '../components/Hero/Hero';
import { heroOne, heroThree, heroTwo } from '../data/HeroData';
import Navbar from '../components/Navbar/Navbar';
// Hero Feature Content Carousel

const Home = () => {
	return (
		<>
			<Navbar />
			<Hero />
			<Content {...heroOne} />
			<Content {...heroTwo} />
			{/* <Features /> */}
			{/* <Content {...heroThree} /> */}
			{/* <Carousel /> */}
		</>
	);
};

export default Home;
