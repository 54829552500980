export const pricingData = [
	{
		title: 'Apple',
		description: 'Download it via App Store Now',
		image:'./assets/googleqr.png',
		features: ['Secure accounts', 'Best for small business', 'Easy', 'Affordable', '1GB RAM'],
	},
	{
		title: 'Android',
		description: 'Download it via Google Play Store Now',
		image:'./assets/googleqr.png',
		features: [
			'Secure accounts',
			'Best for small business',
			'Customizable',
			'3GB RAM',
			'1TB SSD',
		],
	},

];
