import React from 'react';
import UserAgreement from '../components/User-agreement/UserAgreement';
import Navbar from '../components/Navbar/Navbar';
function UserAgreementPage() {
	return (
		<>
			<Navbar />
			<UserAgreement />
		</>
	);
}

export default UserAgreementPage;
