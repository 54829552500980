export const heroOne = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Coverage',
	},
	headline: "Personalized coverage",
	description: 'Covering a wide range of sports and leagues, including soccer, basketball, tennis, baseball, and more. Personalized recommendations provide you with events that align with your interests and preferences.',
	buttonLabel: 'Find Out More',
	linkTo: 'https://play.google.com/store/apps/details?id=com.kincho.sportreview&hl=gsw&gl=US&referrer=utm_source%3Dgoogle%26utm_medium%3Dorganic%26utm_term%3Dplaystore+sportchitz',
	imgStart: 'start',
	img: './assets/hero1.png',
	start: 'true',
};

export const heroTwo = {
	reverse: false,
	inverse: false,
	topLine: {
		text: 'Informative',
	},
	headline: 'Event Information',
	description: 'Xscore.ai caters to sports enthusiasts, event fanatics, and anyone seeking real-time event information. Whether you crave the latest game insights or need timely event results, Xscore.ai is your ultimate companion',
	buttonLabel: 'View App',
	linkTo: 'https://play.google.com/store/apps/details?id=com.kincho.sportreview&hl=gsw&gl=US&referrer=utm_source%3Dgoogle%26utm_medium%3Dorganic%26utm_term%3Dplaystore+sportchitz',
	imgStart: 'start',
	img: './assets/hero2.png',
	start: 'true',
};

export const heroThree = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Effortless & Smooth',
	},
	headline: 'WATCH ANYTIME, ANYWHERE',
	description: 'Stream on your smart TVs and connected devices, at home or on the go.',
	buttonLabel: 'See All Devices',
	linkTo: '/',
	imgStart: 'start',
	img: './assets/devices.png',
	start: 'true',
};

// CHINESE TRANSLATION
export const heroOneCN = {
	reverse: true,
	inverse: true,
	topLine: {
		text: '赛程',
	},
	headline: "重要时刻",
	description: '通过直观的问答界面，无缝访问赛事详情，包括赛程、比赛结果和实时更新。保持最新的体育新闻和赛事亮点，确保你不会错过任何重要时刻。覆盖广泛的体育项目和联赛，包括足球、篮球、网球、棒球等。个性化推荐为你提供与你的兴趣和偏好相符的赛事。',
	buttonLabel: '了解更多',
	linkTo: 'https://play.google.com/store/apps/details?id=com.kincho.sportreview&hl=gsw&gl=US&referrer=utm_source%3Dgoogle%26utm_medium%3Dorganic%26utm_term%3Dplaystore+sportchitz',
	imgStart: 'start',
	img: './assets/hero1.png',
	start: 'true',
};

export const heroTwoCN = {
	reverse: false,
	inverse: false,
	topLine: {
		text: '赛事信息',
	},
	headline: '最好的体育直播平台',
	description: 'Xscore.ai迎合体育爱好者、赛事迷和寻求实时赛事信息的任何人。无论你渴望最新的比赛见解还是需要及时的赛事结果，Xscore.ai都是你的终极伴侣',
	buttonLabel: '查看应用程序',
	linkTo: 'https://play.google.com/store/apps/details?id=com.kincho.sportreview&hl=gsw&gl=US&referrer=utm_source%3Dgoogle%26utm_medium%3Dorganic%26utm_term%3Dplaystore+sportchitz',
	imgStart: 'start',
	img: './assets/hero2.png',
	start: 'true',
};

export const heroThreeCN = {
	reverse: true,
	inverse: true,
	topLine: {
		text: '轻松流畅',
	},
	headline: '随时随地观看',
	description: '无论在家中还是在旅途中，都可以在智能电视和连接的设备上进行流式传输。',
	buttonLabel: '查看所有设备',
	linkTo: '/',
	imgStart: 'start',
	img: './assets/devices.png',
	start: 'true',
};

