import React from 'react';
import Carousel from '../components/Carousel/Carousel';
import { Content } from '../components/Content/Content';
import Features from '../components/Features/Features';
import HeroCN from '../components/Hero/HeroCN';
import { heroOneCN, heroThreeCN, heroTwoCN } from '../data/HeroData';
import NavbarCN from '../components/Navbar/NavbarCN';
// Hero Feature Content Carousel

const HomeCN = () => {
	return (
		<>
            <NavbarCN />
			<HeroCN />
			<Content {...heroOneCN} />
			<Content {...heroTwoCN} />
			{/* <Features /> */}
			{/* <Content {...heroThreeCN} /> */}
			{/* <Carousel /> */}
		</>
	);
};

export default HomeCN;
